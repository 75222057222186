import React, { useEffect, useRef } from 'react';
import Navbar from '../components/navbar';
import Overview from './overview';
import FeaturedProjects from './featured-projects';
import Awards from './awards';
import Testimonials from './testimonials';
import Blogs from './blogs';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
function Home() {
  
  return (
    <div className='wrapper'>
      <Helmet>
      <title>Saya Homes - Delhi-NCR Leading Real Estate Developer</title>
<meta name="description" content="Saya Homes is a trusted name in Delhi-NCR real estate industry, renowned for delivering exceptional residential and commercial projects. "/>
<meta name="keywords" content="saya homes, property in noida, best property in greater noida, property for sale in noida, luxury residential property in greater noida, residential property in ghaziabad, residential projects in noida, apartments in greater noida, commercial projects in greater noida west, housing projects in ghaziabad, construction company in noida"/>
<meta name="language" content="English" />
<meta name="Publisher" content="Saya Homes" />
<meta name="Author" content="Saya Homes" />
<meta name="audience" CONTENT="all" />
<meta name="copyright" content="https://sayahomes.com/" />
<meta name="web_author" content="https://sayahomes.com/" />
<meta name="host" CONTENT="https://sayahomes.com/" />
<meta name="contactOrganization" CONTENT="https://sayahomes.com/" />
<meta name="contactStreetAddress1" CONTENT="Delhi-NCR Leading Real Estate Developer" />
<meta name="contactCity" CONTENT="Delhi NCR" />
<meta name="contactCountry" CONTENT="India" />
<meta name="identifier-URL" CONTENT="https://sayahomes.com/" />
<meta name="distribution" CONTENT="Global" />
<meta name="revisit-after" content="Daily" />
<meta name="document-type" content="Public" />
<meta name="Expires" content="never" />
<meta property="og:url" content="article" />
<meta name="allow-search" content="yes"/>
<meta name="Rating" content="General" />
<meta property="og:url" content="https://sayahomes.com/" />
<link rel="canonical" href="https://sayahomes.com/" />
<meta property="og:title" content="Delhi-NCR Leading Real Estate Developer" />
<meta property="og:description" content="Saya Homes is a trusted name in Delhi-NCR real estate industry, renowned for delivering exceptional residential and commercial projects." />
        </Helmet>
      <Navbar />
      
      <div className="banner  w-100 scrollto carousel slide" data-pause="false" data-timeout="3000" data-ride="carousel">
                        <div class="carousel-inner h-100">
                            <div class="carousel-item h-100 active">
                                <picture>
                                  <source media="(max-width:425px)" srcset="assets/images/Banner-biztop-m.webp" />
                                  <img src="assets/images/Banner-biztop.webp" class="h-100 object-cover" alt="Banner" />
                                </picture>
                            </div>
                            <div class="carousel-item h-100 ">
                                <picture>
                                  <source media="(max-width:425px)" srcset="assets/images/Banner-gold-avenue-m.webp" />
                                  <img src="assets/images/Banner-gold-avenue.webp" class="h-100 object-cover" alt="Banner" />
                                </picture>
                            </div>
                            <div class="carousel-item h-100 ">
                                <picture>
                                  <source media="(max-width:425px)" srcset="assets/images/Banner-piazza-m.webp" />
                                  <img src="assets/images/Banner-piazza.webp" class="h-100 object-cover" alt="Banner" />
                                </picture>
                            </div>
                        </div>
      </div>
      <Overview />
      <FeaturedProjects />
      <Awards />
      <Testimonials />
      <Blogs />
      <Footer />
    </div>
  );
}

export default Home;
